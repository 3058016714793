:root {
    --size: 1;
    --background: #000;
    --color1: #d25778;
    --color2: #ec585c;
    --color3: #e7d155;
    --color4: #56a8c6;
  }
  
  body {
    background: var(--background);
    color: white;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  * {
    box-sizing: border-box;
  }
  
  .ticket-visual_visual {
    width: 600px;
    height: 300px;
    transform: scale(0.7);
    position: relative;
    transition: all 300ms cubic-bezier(0.03, 0.98, 0.53, 0.99) 0s;
    background: linear-gradient(
      to right,
      var(--color1),
      var(--color2),
      var(--color3),
      var(--color4)
    );
    border-radius: 20px;
    padding: 5px;
  }
  @media (max-width: 768px) {
    .ticket-visual_visual{
      width: 360px;
      transform: scale(0.85);
    }
  }
  .ticket-visual_visual:before {
    content: '';
    display: block;
    position: absolute;
    top: 120px;
    left: -30px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: var(--color1);
    z-index: 2;
  }
  
  .ticket-visual_visual:after {
    content: '';
    display: block;
    position: absolute;
    top: 120px;
    right: -30px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: var(--color4);
    z-index: 2;
  }
  
  .ticket-visual-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    background: var(--background);
    border-radius: 15px;
  }
  .ticket-visual-wrapper:before {
    content: '';
    display: block;
    position: absolute;
    top: 120px;
    left: -30px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #121212;
    z-index: 3;
  }
  
  .ticket-visual-wrapper:after {
    content: '';
    display: block;
    position: absolute;
    top: 120px;
    right: -30px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #121212;
    z-index: 3;
  }
  
  .left {
    position: absolute;
    top: 110px;
    left: -50px;
    width: 50px;
    height: 100px;
    background: #121212;
    z-index: 4;
  }
  
  .right {
    position: absolute;
    top: 110px;
    right: -50px;
    width: 50px;
    height: 100px;
    background:#121212;
    z-index: 4;
  }
  .ticket-visual_ticket-number-wrapper {
    position: absolute;
    transform: scaleY(0.9);
    right: 35px;
    bottom: 0;
  }
  
  .ticket-visual_ticket-number {
    transform: rotate(90deg) translateY(calc(100px * var(--size)));
    transform-origin: bottom right;
    font-size: calc(40px * var(--size));
    font-weight: 500;
    text-align: center;
    padding-bottom: 15px;
    width: calc(320px - 10px);
    line-height: 1;
    border-bottom: 2px dashed #333; 

  }
  @media (max-width: 768px) {
    .ticket-visual_ticket-number-wrapper {
      position: absolute;
      /* transform: scaleY(1); */
      width: 310px;
      height: fit-content;
      top: 125px;
      left: 20px;
    }
  
    .ticket-visual_ticket-number {
      transform: rotate(0deg);
      transform-origin: bottom left;
      font-size: 30px;
      font-weight: 700;
      text-align: center;
      padding-bottom: 0;
      width: auto;
      line-height: 1.5;
      border-top: 2px dashed #333;
    }
  }