
      .skills {
        height: fit-content;
        min-width: fit-content;
        font-size: 19px;
        border: 2px solid white;
        border-radius: 10px;
        padding: 5px;
        z-index: 5;
        color: white;
        margin: 5px;
        text-align: center;
      }
      .logo{
        height: 50px;
        width: 50px;
      }
      @media (max-width: 768px) {
        .skills {
          font-size: 14px;
        }
        .logo{
          height: 30px;
          width: 30px;
        }
      }
