/* Padding */
.paddingX {
    padding-left: 6px;
    padding-right: 6px;
  }
  @media (min-width: 640px) {
    .paddingX {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  
  .paddingY {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  @media (min-width: 640px) {
    .paddingY {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
  
  .padding {
    padding: 10px 6px;
  }
  @media (min-width: 640px) {
    .padding {
      padding: 16px;
    }
  }
  
  /* Hero Head Text */
  .heroHeadText {
    font-weight: 900;
    color: #ffffff;
    font-size: 40px;
    line-height: 1;
    margin-top: 2px;
  }
  @media (min-width: 480px) {
    .heroHeadText {
      font-size: 50px;
    }
  }
  @media (min-width: 640px) {
    .heroHeadText {
      font-size: 60px;
    }
  }
  @media (min-width: 1024px) {
    .heroHeadText {
      font-size: 80px;
      line-height: 98px;
    }
  }
  
  /* Hero Sub Text */
  .heroSubText {
    color: #dfd9ff;
    font-weight: 500;
    font-size: 16px;
  }
  @media (min-width: 480px) {
    .heroSubText {
      font-size: 20px;
    }
  }
  @media (min-width: 640px) {
    .heroSubText {
      font-size: 26px;
    }
  }
  @media (min-width: 1024px) {
    .heroSubText {
      font-size: 30px;
      line-height: 40px;
    }
  }
  
  /* Section Head Text */
  .sectionHeadText {
    color: #ffffff;
    font-weight: 900;
    font-size: 30px;
  }
  @media (min-width: 480px) {
    .sectionHeadText {
      font-size: 40px;
    }
  }
  @media (min-width: 640px) {
    .sectionHeadText {
      font-size: 50px;
    }
  }
  @media (min-width: 768px) {
    .sectionHeadText {
      font-size: 60px;
    }
  }
  
  /* Section Sub Text */
  .sectionSubText {
    font-size: 14px;
    color: #aaa6c3;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }
  @media (min-width: 640px) {
    .sectionSubText {
      font-size: 18px;
    }
  }
  .para {
    margin: 1rem;
    color: #aaa6c3;
    font-size: 17px;
    max-width: 100vw; /* responsive width */
    line-height: 30px;
  }
  .technologies {
    margin: 5rem auto;
    display: flex;
    flex-wrap: wrap;
    gap: 2.5rem;
    justify-content: center; /* centers items horizontally */
    align-items: center; /* centers items vertically */
  }
  
  .techCard{
  background-color: #000; /* replace with the actual color used for bg-tertiary */
  border-radius: 20px;
  padding: 5rem 3rem;
  height: 280px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  }
  .card{
    width: 100%;
    background: linear-gradient(to right, #d25778, #ec585c,#e7d155,#56a8c6); /* replace with the actual colors used for green-pink-gradient */
    padding: 1px;
    border-radius: 20px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); /* replace with the actual values used for shadow-card */
  }